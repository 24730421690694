import {Model} from "./";
import {Api} from "../api";

class DeliveryModel extends Model {

    constructor() {
        super(new Api("/delivery"));
    }

    getTypes(paymentId,thenFunction, errorFunction) {
        this.q.setDopUrl('/types/'+paymentId); // paymentId
        this.q.read({}, thenFunction, errorFunction);
    }

    getDates(PointsId,DeliveryType,thenFunction, errorFunction) {
        this.q.setDopUrl('/dates/'+PointsId +'?type='+DeliveryType);
        this.q.read({}, thenFunction, errorFunction);
    }

    getPoints(clientId,thenFunction, errorFunction) {
        this.q.setDopUrl('/points/'+clientId);
        this.q.read({}, thenFunction, errorFunction);
    }

    update(id, ob, thenFunction, errorFunction) {
        this.q.setDopUrl('/points/updateTradePoints/' + id);
        this.q.update(ob, thenFunction, errorFunction);
    }
    getPaymentClientTypeId(clientTypeId,thenFunction, errorFunction) {
        this.q.setDopUrl('/payment/'+clientTypeId);
        this.q.read({}, thenFunction, errorFunction);
    }
    getDeliveryDays(siteId,thenFunction, errorFunction) {
        this.q.setDopUrl('/delivery_days/'+siteId);
        this.q.read({}, thenFunction, errorFunction);
    }

}

export default new DeliveryModel();
