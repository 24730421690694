import {action, makeAutoObservable} from "mobx"
import {RootStore} from "../index";
import OrderModel from "../../model/OrderModel";
import ClientModel from "../../model/ClientModel";
import DeliveryModel from "../../model/DeliveryModel";
import UserModel from "../../model/UserModel";
import ExcelModel from "../../model/ExcelModel";
import WebPayModel from "../../model/WebPayModel";


export class PageOrderStore {
    private rootStore: RootStore | undefined;
    files = [];
    isModalOpen = false;
    limitRow = 9999999;
    arModalDataDetail = [];
    clientId: string = '0';
    onChecks = 0;
    arItems = [];
    arModalData = [];
    selectedOption = {
        Client: {value: '', label: ''},
        Status: {label: 'Заказы со статусом', value: ''},
        Points: {label: 'Торговая точка', value: ''},
        Users: {label: 'Заказы учётной записи', value: ''},
    }
    csvData = [];
    loading = false;
    dateFrom = '';
    dateTo = '';
    search = '';
    radioId = 0;
    radioIdStatus = '';
    setSelectedOption = {
        Client: null,
        Status: null,
        Points: null,
        Users: null,
    }
    isPopupWebPayAboutRedirect = false
    options = {
        Client: [],
        Status: [
            {value: 0, label: 'Все'},
            {value: 'canceled', label: 'Отменен'},
            {value: 'blocked', label: 'Заблокирован'},
            {value: 'harmonization', label: 'На согласовании'},
            {value: 'accepted', label: 'Подтвержден'},
            {value: 'assembling', label: 'На сборке'},
            {value: 'assembled', label: 'Собран'},
            {value: 'loaded', label: 'Отгружен'},
            {value: 'shipped', label: 'Доставлен'},
            {value: 'created', label: 'Создан'},
            {value: 'draft', label: 'Черновик'},
        ],
        Points: [],
        Users: [],
    }
    pagination = {
        countPage: 1,
        countRow: 1,
        currentPage: 1
    }
    isShow: boolean = false;
    paymentDoc: any = ''

    public error: string = '';
    setRadio = (id: number, status: string) => {
        this.radioId = id;
        this.radioIdStatus = status;
    }
    redirectWebPayUrl: string = ""
    openCircle: boolean = false

    @action
    setCsvData = (event: any, done: (p: boolean) => any) => {
        if (!this.loading) {
            this.loading = true;
            if (this.radioId) {
                OrderModel.getOrderCsv(this.radioId, (result: any) => {
                    this.csvData = result.data;
                    this.loading = false;
                    done(true);
                }, (error: any) => {
                    done(false);
                    this.loading = false;
                })
            }
        }
        return false;
    }

    setDateTo = (e: { target: { value: string } }) => {
        this.dateTo = e.target.value;
    }

    setDateFrom = (e: { target: { value: string } }) => {
        this.dateFrom = e.target.value;
    }

    setSearch = (e: { target: { value: string } }) => {
        this.search = e.target.value;
    }

    orderToBasket = (id: number) => {
        OrderModel.repeat({
            id: id,
            clientId: this.rootStore?.pageBasketStore.selectedOption.Client.value
        }, this.repeatOrder, this.setError)
    }

    constructor(rootStore?: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        // @ts-ignore
        this.setSelectedOption = {
            // @ts-ignore
            Client: this.setSelectedOptionClient,
            // @ts-ignore
            Status: this.setSelectedOptionStatus,
            // @ts-ignore
            Points: this.setSelectedOptionPoints,
            // @ts-ignore
            Users: this.setSelectedOptionUsers,
        }
    }

    setSelectedOptionClient = (e: any) => {
        this.selectedOption.Client = e;
    }

    setSelectedOptionStatus = (e: any) => {
        this.selectedOption.Status = e;
    }

    setSelectedOptionPoints = (e: any) => {
        this.selectedOption.Points = e;
    }

    setSelectedOptionUsers = (e: any) => {
        this.selectedOption.Users = e;
    }

    @action init() {
        this.initFilter();
    }

    @action initFilter() {
        ClientModel.getClients(this.setClients, this.setError);
        UserModel.getAllChildren(this.setUsers, this.setError);
    }

    @action
    setClients = (result: any) => {
        this.setData(result.data, 'Client', 'fullName');
        let clientId = this.selectedOption?.Client?.value;
        if (clientId) {
            this.clientId = clientId;
        }

        this.reload(true);

        if (clientId) {
            DeliveryModel.getPoints(clientId, this.getPoints, this.setError);
        }
    }

    @action
    setUsers = (result: any) => {
        this.setData(result.data.row, 'Users', 'email', false);
    }

    defaultFilter() {
        this.selectedOption = {
            Client: {value: '', label: ''},
            Status: {label: 'Заказы со статусом', value: ''},
            Points: {label: 'Торговая точка', value: ''},
            Users: {label: 'Заказы учётной записи', value: ''},
        }
        this.dateFrom = '';
        this.dateTo = '';
        this.search = '';
        this.initFilter();
    }

    @action
    getPoints = (result: any) => {
        this.setData(result.data, 'Points', 'name', false);
    }

    setData(result: any, keyData: string, keyName: string, set = true) {
        // @ts-ignore
        this.options[keyData] = [];
        if (result?.length) {
            // @ts-ignore
            this.options[keyData].push({
                // @ts-ignore
                value: 0,
                // @ts-ignore
                label: 'Все',
            });
            result.map((item: any) => {
                // @ts-ignore
                this.options[keyData].push({
                    // @ts-ignore
                    value: item?.id,
                    // @ts-ignore
                    label: item[keyName],
                });
                return item;
            });
            if (set) {
                // @ts-ignore
                this.selectedOption[keyData] = {
                    // @ts-ignore
                    value: result[0]?.id,
                    // @ts-ignore
                    label: result[0][keyName],
                }
            }
        }
    }


    @action
    setOrders = (result: any) => {
        result.data.rows.map((item: any) => {
            item.createdAt = new Date(item.createdAt);
            item.shipDate = new Date(item.shipDate);
            return item;
        });
        this.arItems = result.data.rows;
        this.pagination = result.data.pagination;
        if (window.localStorage.getItem("order") === "1" && this.clientId) {
            this.isPopupWebPayAboutRedirect = true
        }
    }
    @action
    onCloseModalWebPayAboutRedirect = () => {
        this.isPopupWebPayAboutRedirect = false
        // @ts-ignore
        this.showProdDetail(this.arItems[0].id)
    }

    @action
    setError = (error: any) => {
        console.log(error)
    }

    onCurrentPage = (page: number) => {
        this.pagination.currentPage = page;
        this.reload();
    }

    filterSubmit = () => {
        this.reload(true);
    }
    filterClear = () => {
        this.defaultFilter();
        this.reload(true);
    }

    setFilter = () => {
        let filter = {
            clientId: this.selectedOption.Client?.value,
        };
        if (this.selectedOption.Status?.value) {
            // @ts-ignore
            filter = {...filter, status: this.selectedOption.Status?.value}
        }
        if (this.selectedOption.Points?.value) {
            // @ts-ignore
            filter = {...filter, tradePointId: this.selectedOption.Points?.value}
        }
        if (this.selectedOption.Users?.value) {
            // @ts-ignore
            filter = {...filter, userId: this.selectedOption.Users?.value}
        }

        if (this.dateFrom) {
            // @ts-ignore
            filter = {...filter, createdAtFrom: this.dateFrom}
        }

        if (this.dateTo) {
            // @ts-ignore
            filter = {...filter, createdAtTo: this.dateTo}
        }

        if (this.search) {
            // @ts-ignore
            filter = {...filter, search: this.search}
        }

        return filter;
    }

    reload(init: boolean = false) {
        if (init) {
            this.pagination.currentPage = 1;
        }
        OrderModel.getOrders(this.getOrderModelData(), this.setOrders, this.setError);
    }

    getOrderModelData() {
        return {
            json: {
                limit: this.limitRow,
                filter: this.setFilter(),
            },
            page: this.pagination.currentPage
        };
    }

    deleteOrder = (id: number) => {
        if (window.confirm('Удалить черновик?')) {
            OrderModel.deleteOrder(id, this.setReload, this.setError);
        }
    }
    createRepeatOrder = (clientId: number, orderId: number) => {
        let ob = {
            id: orderId,
            clientId: clientId
        }
        OrderModel.repeat(ob, this.repeatOrder, this.setError);
    }

    setReload = (result: any) => {
        this.reload();
        this.radioId = 0;
        this.radioIdStatus = '';
    }

    repeatOrder = (result: any) => {
        this.openCircle = true
        setTimeout(() => {
            this.setRedirectUrlAndStopOpenCircle()
        }, 2000);

    }

    setRedirectUrlAndStopOpenCircle() {
        this.openCircle = false
        this.rootStore?.mainStore.setRedirectUrl('/basket');
        window.location.replace('/basket')
    }


    orderToCreated = (id: number) => {
        OrderModel.changeStatus(id, this.setReload, this.setError);
    }

    @action
    onCloseModal = () => {
        this.isModalOpen = false;
    }

    @action
    onOpenModal = (id: number) => {
        this.arModalData = [];
        this.isModalOpen = true;
        OrderModel.getProductInfoOrder(id, this.setModalData, this.setError)
    }

    @action
    setModalData = (result: any) => {
        this.arModalData = result.data;
    }

    showProdDetail(id: any) {
        window.localStorage.setItem("order", "0")
        if (this.onChecks === id) {
            this.onChecks = 0;
        } else {
            this.onChecks = id;
            // @ts-ignore
            this.onOpenModalDetail(id);
        }
    }

    @action
    onOpenModalDetail = (id: number) => {

        this.arModalDataDetail = [];
        OrderModel.getProductInfoOrder(id, this.setModalDataDetail, this.setError)
        WebPayModel.getOrderWebPayLink(id, this.setWebPayLink, this.setError)
    }

    @action
    setModalDataDetail = (result: any) => {
        this.paymentDoc = result.data[0].paymentDoc
        this.arModalDataDetail = result.data;
    }

    @action
    setWebPayLink = (result: any) => {
        if (result) {
            this.redirectWebPayUrl = result.data.data.redirectUrl
        }
    }

    toggleFIeldset = () => {
        if (!this.isShow) {
            this.rootStore?.componentFilterCatalogStore.initBrandForUser()
            this.rootStore?.pageCatalogStore.getAllCatalogList()
        }
        this.setIsShown();
    }


    setIsShown() {
        this.isShow = !this.isShow
    }

    @action
    onDrop = (files: any) => {
        this.files = files;
        let file = files[0];
        let formData = new FormData();
        formData.append('file', file);
        ExcelModel.uploadOrderBeautyhouse(formData, (result: any) => {
            window.location.href = '/basket';
        }, (error: any) => {
            console.log(error);
        });
    };
}
