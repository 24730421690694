import React from 'react';
import moment from "moment";


export class Copyright extends React.Component {
    render() {

        let date = moment(new Date()).format("YYYY")
        let Width = window.innerWidth
        return (
            <>
                <div className="copyright">
                    <div className="container copyright__grid">
                        <div className="copyright__text" style={{ textAlign: "left"}}  >
                            <p style={{  fontSize: 12}}>
                                {date} - {Number(date) + 1} © beautyhouse.by — Интернет-магазин профессиональной
                                косметики.  ООО «Сэльвин», УНП 100819821, Беларусь, г. Минск, ул. Академика Купревича, 14, каб. 37.
                                Дата регистрации в торговом реестре 28.02.2023 №552842. Время работы с 9.00 до 17.30 .
                            </p>
                            <p style={{  fontSize: 12}}>
                                Разработано Copyright © 2024 
                                 <a target='_blank' style={{color: '#b17882', cursor: 'pointer'}} href='https://it-prof.by/'> Айти Проф Групп</a> 
                                 . Все права защищены.
                            </p>
                        </div>
                        <img className="copyright__payment-icons" src="/images/pic--payment-icons.png" width={Width > 500 ? "40%" : "100%"}  alt=""/>
                    </div>
                </div>
            </>
        )
    }
}
